import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import agent from '../../arc/agent/agent';

const accountInitialState = {
	status: 'idle',
	data: {
		loggedIn: false,
		errors: null,
		api: {
			csrf: null,
			sid: null,
			version: null,
			time: null
		},
		account: {
			id: 'userid',
			email: null,
			name: null,
			preferredLanguage: ['de']
		},
		devLoginToken: null,
		loginEmail: null,
		profiles: []
	},
	error: {}
};

// server api calls actions
export const initSession = createAsyncThunk(
	'account/initSession',
	(options, {getState}) =>
		agent
			.get(`${getState().status.apiUrl}/init`)
			.set('Accept', 'application/json')
			.then((response) => ({body: response.body, headers: response.headers}))
			.catch((error) => error)
);

export const fetchAccounts = createAsyncThunk(
	'account/fetchAccounts',
	(options, {getState}) => {
		const query = {
			...options
		};
		return agent
			.get(`${getState().status.apiUrl}/account/collection`)
			.query(query)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error);
	}
);

export const fetchAccount = createAsyncThunk(
	'account/fetchAccount',
	(options, {getState}) =>
		agent
			.get(`${getState().status.apiUrl}/account/get`)
			.query({id: options.id})
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);

export const storeAccount = createAsyncThunk(
	'account/storeAccount',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/account`)
			.send({account: getState().account.data.account})
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);

export const loginWithEmail = createAsyncThunk(
	'account/loginWithEmail',
	(options, {getState}) => {
		Cookies.set('arc-email', options.email, {expires: 99999});
		return agent
			.post(`${getState().status.apiUrl}/account/newLoginToken`)
			.send({email: options.email})
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error);
	}
);

export const authenticateWithToken = createAsyncThunk(
	'account/authenticateWithToken',
	async (options, {dispatch, getState}) => {
		const loginResponse = await agent
			.post(`${getState().status.apiUrl}/account/login`)
			.send({
				email: options.email,
				token: options.token,
				consent: options.consent,
				longSession: options.longSession
			})
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => {
				throw error;
			});
		return loginResponse;
	}
);


export const createUser = createAsyncThunk(
	'account/createUser',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/account/createUser`)
			.send(options)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);
export const createAdmin = createAsyncThunk(
	'account/createAdmin',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/account/createAdmin`)
			.send(options)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);
export const createOperator = createAsyncThunk(
	'account/createOperator',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/account/createOperator`)
			.send(options)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);
export const inviteUser = createAsyncThunk(
	'account/inviteUser',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/account/inviteUser`)
			.send(options)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);
export const updateAccount = createAsyncThunk(
	'account/updateAccount',
	(options, {getState}) =>
		agent
			.put(`${getState().status.apiUrl}/account/update`)
			.send(options)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);
export const logout = createAsyncThunk(
	'account/logout',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/account/logout`)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);

export const accountsAdapter = createEntityAdapter();
export const initialAccountsQuery = {
	search: '',
	role: 'user', // 'user', 'admin', 'operator'
	isEnabled: -1,
	isTest: -1,
	hasLoggedInOnce: -1,
	orderBy: 'id',
	orderByDirection: 'desc', // 'asc', 'desc'
	page: null
};

function storePermissions(permissions, state) {
	const globalPermissions = permissions.find((p) => p[0] === '0');
	state.globalPermissions = globalPermissions ? globalPermissions[1] : [];

	const profilePermissions = {};
	permissions
		.filter((p) => p[0] !== '0')
		.forEach((p) => {
			profilePermissions[p[0]] = p[1];
		});
	state.profilePermissions = profilePermissions;
}

export const accountSlice = createSlice({
	name: 'account',
	initialState: {
		status: 'idle',
		data: {
			loggedIn: false,
			errors: null,
			api: {
				csrf: null,
				sid: null,
				version: null,
				time: null
			},
			account: {
				id: '',
				role: 'user',
				isTest: 0,
				email: null,
				name: null,
				preferredLanguage: ['de'],
				permissions: []
			},
			devLoginToken: null,
			loginEmail: null,
			profiles: []
		},
		query: initialAccountsQuery,
		globalPermissions: [],
		profilePermissions: {},
		accounts: accountsAdapter.getInitialState(),
		accountsPagination: {
			amount: 0,
			totalAmount: 0,
			pagesAmount: 1,
			page: 1
		},
		error: {}
	},

	// standard actions: for example update data
	// export actions at end of file
	reducers: {
		setLoginEmail: (state, action) => {
			state.data.loginEmail = action.payload.loginEmail;
		},
		setPreferredLanguage: (state, action) => {
			state.data.preferredLanguage = action.payload.value;
		},
		setAccountsQuery: (state, action) => {
			// merge payload with current query
			state.query = action.payload;
		},
		setProfile: (state, action) => {
			// merge payload with current query
			state.query = action.payload;
		},
		clearAccount: (state) => {
			state.data.account = {...accountInitialState.data.account};
			state.data.devLoginToken = null;
			accountsAdapter.removeAll(state.accounts);
			Cookies.remove('arc-profile', { path: '/' });
		}
	},

	// async actions: server api calls workflows
	extraReducers: (builder) => {

		builder.addCase(initSession.fulfilled, (state, action) => {
			state.status = 'idle';
			state.data.api.csrf = action.payload.headers['arc-csrf-token'];
			state.data.api.sid = action.payload.headers['arc-sid'];
			state.data.api.version = action.payload.headers['arc-api-version'];
			state.data.api.time = action.payload.headers['arc-api-time'];
			if (action.payload.body.account !== null) {
				state.data.account = action.payload.body.account;
				storePermissions(action.payload.body.account.permissions, state);
				state.data.loggedIn = true;
			} else {
				state.data.loggedIn = false;
			}
			state.data.account = action.payload.body.account;
			state.error.message = null;
		});
		builder.addCase(initSession.pending, (state, action) => {
			state.status = 'pending';
			state.data.api.csrf = null;
			state.data.api.csrf = null;
			state.data.api.version = null;
			state.data.api.time = null;
			state.error.message = null;
		});
		builder.addCase(initSession.rejected, (state, action) => {
			state.status = 'error';
			state.data.api.csrf = null;
			state.data.api.csrf = null;
			state.data.api.version = null;
			state.data.api.time = null;
			state.error.message = 'error';
		});

		builder.addCase(storeAccount.fulfilled, (state, action) => {
			state.status = 'idle';
			state.error.message = null;
		});
		builder.addCase(storeAccount.pending, (state, action) => {
			state.status = 'saving';
			state.error.message = null;
		});
		builder.addCase(storeAccount.rejected, (state, action) => {
			state.status = 'error';
			state.error.message = 'error';
		});

		builder.addCase(loginWithEmail.fulfilled, (state, action) => {
			if (!(action.payload?.status === 422)) {
				state.data.loginEmail = action.meta.arg.email;
				state.data.loggedIn = false;
			} else {
				state.data.loggedIn = false;
			}
			state.status = 'idle';
		});
		builder.addCase(loginWithEmail.pending, (state, action) => {
			state.status = 'loading';
			state.error = {};
		});
		builder.addCase(loginWithEmail.rejected, (state, action) => {
			state.data = {...accountInitialState.data};
			state.status = 'idle';
			state.error = action.payload;
		});

		builder.addCase(authenticateWithToken.fulfilled, (state, action) => {
			if (action.payload && action.payload.status === 422) {
				// request ok but login failed
				state.data.account = {...accountInitialState.data.account}; // reset account and
				state.data.errors = action.payload.response.body; // save error info for display in frontend

				state.data.loggedIn = false;
				state.data.loginFailed = true;
			} else if (action.payload && action.payload.account) {
				// login successful
				state.data.account = action.payload.account; // save account in state
				storePermissions(action.payload.account.permissions, state);
				state.data.loggedIn = true;
				state.data.loginFailed = false;
			} else {
				// request failed
				state.data.account = {...accountInitialState.data.account};
				state.data.loggedIn = false;
				state.data.loginFailed = true;
			}
			state.status = 'idle';
		});
		builder.addCase(authenticateWithToken.pending, (state, action) => {
			console.log('authenticateWithToken.pending', action.payload);
			state.status = 'loading';
			state.error = {};
		});
		builder.addCase(authenticateWithToken.rejected, (state, action) => {
			console.log('authenticateWithToken.rejected', action.payload);
			state.data.loggedIn = false;
			state.status = 'idle';
			state.error = action.payload;
		});

		builder.addCase(logout.fulfilled, (state, action) => {
			state.data.loggedIn = false;
			state.data.account = {...accountInitialState.data.account};
			state.data.devLoginToken = null;
			accountsAdapter.removeAll(state.accounts);
			state.data.globalPermissions = [];
			state.data.profilePermissions = {};
			Cookies.remove('arc-profile', { path: '/' });
			state.status = 'idle';
			state.error = {};
		});
		builder.addCase(logout.pending, (state, action) => {
			state.status = 'loading';
			state.error = {};
		});
		builder.addCase(logout.rejected, (state, action) => {
			state.status = 'idle';
			state.error = action.payload;
		});

		builder.addCase(fetchAccounts.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(fetchAccounts.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			accountsAdapter.removeAll(state.accounts);
			accountsAdapter.upsertMany(state.accounts, action.payload.collection.items);
			state.accountsPagination.amount = action.payload.collection.amount;
			state.accountsPagination.totalAmount = action.payload.collection.totalAmount;
			state.accountsPagination.pagesAmount = action.payload.collection.pagesAmount;
			state.accountsPagination.page = action.payload.collection.page;
		});
		builder.addCase(fetchAccounts.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.error.message;
		});

		builder.addCase(fetchAccount.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(fetchAccount.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			accountsAdapter.upsertOne(state.accounts, action.payload.item);
		});
		builder.addCase(fetchAccount.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.error.message;
		});

		builder.addCase(createUser.fulfilled, (state, action) => {
			state.status = 'idle';
			state.error = null;
			accountsAdapter.upsertOne(state.accounts, action.payload);
		});
		builder.addCase(createUser.pending, (state, action) => {
			state.status = 'loading';
			state.error = null;
		});
		builder.addCase(createUser.rejected, (state, action) => {
			state.status = 'error';
			state.error = action.error.message;
		});

		builder.addCase(inviteUser.fulfilled, (state, action) => {
			state.status = 'idle';
			state.error = null;
		});
		builder.addCase(inviteUser.pending, (state, action) => {
			state.status = 'loading';
			state.error = null;
		});
		builder.addCase(inviteUser.rejected, (state, action) => {
			state.status = 'error';
			state.error = action.error.message;
		});

		builder.addCase(createAdmin.fulfilled, (state, action) => {
			state.status = 'idle';
			state.error = null;
			accountsAdapter.upsertOne(state.accounts, action.payload);
		});
		builder.addCase(createAdmin.pending, (state, action) => {
			state.status = 'loading';
			state.error = null;
		});
		builder.addCase(createAdmin.rejected, (state, action) => {
			state.status = 'error';
			state.error = action.error.message;
		});

		builder.addCase(createOperator.fulfilled, (state, action) => {
			state.status = 'idle';
			state.error = null;
			accountsAdapter.upsertOne(state.accounts, action.payload);
		});
		builder.addCase(createOperator.pending, (state, action) => {
			state.status = 'loading';
			state.error = null;
		});
		builder.addCase(createOperator.rejected, (state, action) => {
			state.status = 'error';
			state.error = action.error.message;
		});


		builder.addCase(updateAccount.fulfilled, (state, action) => {
			state.status = 'idle';
			state.error = null;
			accountsAdapter.upsertOne(state.accounts, action.payload.item);
		});
		builder.addCase(updateAccount.pending, (state, action) => {
			state.status = 'loading';
			state.error = null;
		});
		builder.addCase(updateAccount.rejected, (state, action) => {
			state.status = 'error';
			state.error = action.error.message;
		});

	}
});

// Rename the exports for readability in component usage
export const {
	selectById: selectAccountById,
	selectIds: selectAccountIds,
	selectEntities: selectAccountEntities,
	selectAll: selectAllAccounts,
	selectTotal: selectTotalAccounts
} = accountsAdapter.getSelectors((state) => state.account.accounts);

export const {
	clearAccount,
	setAccountsQuery,
	setLoginEmail,
	setPreferredLanguage
} = accountSlice.actions;


export default accountSlice.reducer;
