
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's016f68d263953ec2': `Konto Löschen`,
's022ad53b239795d5': `Profilbild entfernen`,
's032cd9f1245d3645': `Maße`,
's0379fc73608ab971': `Fertig`,
's03848d257276adc8': `genaues Datum`,
's03aa3cfb08dff960': `Schlagworte (thematisch)`,
's0491d2d22458cf73': `Maximale Anzahl an Videos überschritten`,
's06b291180b5c2ef2': `Dieses Kunstwerk entfernen, wird es direkt aus Ihrer Liste von Kunstwerken ausblenden.`,
's06ef4755e61fa13f': `Login nicht erfolgreich. Bitte überprüfen Sie Ihre E-Mail und den Login-Code.`,
's071d99b3ab93bbcb': `Künstlergruppe`,
's072af2f9f637f289': `falsche Sprache`,
's08467b5f9b0bbe38': `Fügen Sie eigene Schlagworte zu, nach denen Sie in der Übersicht filtern können`,
's087eb6b22eae8f9c': `Kunstwerk hinzufügen`,
's08c43a07b566d980': `hoch`,
's08d844177965c733': `Filter zurücksetzen`,
's09205907b5b56cda': `Nein`,
's09240e07b5b8d640': `ID`,
's0c599150290fb992': `Kein Text`,
's0c6538ad32621450': str`Kunstwerk "${0}" entfernen, wird es direkt aus Ihrer Liste von
					Kunstwerken ausblenden.
				`,
's0d045841254adc83': `Kurzbeschreibung`,
's0e71a5c50cd56f90': `Profilbild hinzufügen`,
's0f0202c2780ce439': `Untertitel`,
's0f15b434f2922b08': `Ganze Zahl`,
's0f2cb376eeeaa423': `Jahr (Start)`,
's0f8a40190d30e7e3': `Lebenslauf bearbeiten`,
's1219d97b49650c05': `Sichtbarkeit`,
's13df6bd38cbf23ad': `Dateiformate`,
's13e72a6392474063': `Profil gewechselt`,
's1641a1c90d9840ec': `Entwurf`,
's165d3919b108ec51': `Kurzbeschreibung Biografie`,
's17fc59d42b9bc362': `Konto Informationen`,
's1829ba489430c269': `Inventarnummer Kunstfonds `,
's1946c13a177a90a1': `Objektnummern`,
's19b937c88ca5d2b3': `Einladung senden`,
's1add68562a420778': `Detaillierte Beschreibung der Materialien`,
's1b5bba3d9041629a': `Einstellungen speichern`,
's1c1134cbda4fe1ee': `Falsches Format`,
's1c43540556a6117c': `Die Einladung konnte nicht verschickt werden.`,
's1e61bece71b51008': `Gelöschte Einträge`,
's21900f027f2d7cfa': `Durchmesser`,
's219c37011b334110': `Bilder / Medien hinzufügen`,
's2468b69d10791c82': `Stadt`,
's24d03b95f001a869': `Kunstwerk entfernen`,
's26f91b6796e33111': `Wiederherstellen`,
's292da8ff092860a5': `Status wählen ...`,
's2a75c946ef4dcab7': `Verschiedenes`,
's2a94ce1cb475c9c2': `Sie können Personen einladen, die Ihnen bei der Erstellung dieses Profils helfen können. Diese
					können alle Kunstwerke und Profildaten einsehen und verändern.
				`,
's2c57913ffd36d298': `Vorname`,
's30c9440f8f06fc3c': `Maximale Anzahl an Dateien überschritten`,
's31b9c53d98feb660': `Login Code Anfordern`,
's31d694b87ea70f4d': `Falsches Datumsformat`,
's321c03399a143242': `Konto anlegen`,
's3231d0dc6fe30d4a': `Tiefe`,
's32ac2b6848504809': `Nationalität`,
's32b3ac51a9c31f47': `Zum Profil wechseln`,
's332a5235948c1a1d': `Ich akzeptiere die Datenschutzbestimmungen.`,
's336c8e1678a8ff80': `Löschen`,
's3375a4920652b810': `Serien`,
's33b30147022d7f8c': `Code Eingeben`,
's345fbf4889ae0c7f': `Tas Kunstwerk enthält untergeordnete Einträge, bitte löschen Sie diese zuerst.`,
's34aa94e923bb3d75': `Eingabe nicht Teil der Menge`,
's34d7c565f291023e': `Unverkäuflich`,
's35f1f5ccf4706d78': `Sichtbarkeit wählen ...`,
's37083e19c2f9c755': `Nr.`,
's383f38fbc7a28b77': `Datei zu groß`,
's38be98a354b0a135': `Signiert`,
's3a6129602c615ff1': `bearbeiten`,
's3cf89cb47fdde7e9': `Link`,
's3dc7f921134f8d35': `In der Werkverzeichnung des Kunstfonds können Sie Ihr Künstlerprofil, Ihren Lebenslauf und
					Ihre Kunstwerke so einfach oder detailliert bearbeiten, wie Sie möchten.
				`,
's3f665d5e5aaa691c': `Profil Informationen`,
's402ed9d74111a751': `Erstellungsdatum (Ende)`,
's41f7dca46d3f1b4a': `Wert`,
's43e372d3fcabbd0a': `Basisattribute`,
's45ab758ffe13fd9b': `Serien verwalten`,
's477bc7abb235cce6': `Filter ausblenden`,
's47a2a1f01af73025': `ggf. Todestag`,
's4880dc3125543292': `Erwähnungen`,
's49c6478ea452050b': `Lebenslauf konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's4caed5b7a7e5d89b': `Englisch`,
's4cd84215487b493a': `Diagonale`,
's4ced1bd295c3cac3': `E-Mail Adresse der einzuladenden Person`,
's4e33f21fc24666c1': `hoch`,
's4fcd634b4ebc761b': `Abschnitt hinzufügen`,
's5096bdb5f882fb29': `Materialien`,
's517acbf1f96324f9': `Gewicht`,
's523f5d97bb322419': `Konto Einstellungen`,
's552ca8336fe964d0': `Ausstellungshistorie`,
's5540c926a41a6be9': `Profil hinzufügen`,
's5590dbf7e425789d': `Bezeichnung`,
's58d4bced0b668ae6': `Keine Zahl`,
's5a41c3a63f1c18af': `Profil Enstellungen`,
's5ccc0b389bfe6048': `Keine Serien vorhanden`,
's5d7d95ba0d2c4869': `Das Formular konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's5d929ff1619ac0c9': `Suche`,
's5e8250fb85d64c23': `Schließen`,
's5e9f4a47a1f2cd25': `Kunstwerk gespeichert`,
's5f175fdca113e5f1': `Weitere Objektnummer`,
's63b877fd01da50e6': str`Der Account "${0}" wird sofort gelöscht.`,
's63d894b1ddb06289': `Beschreibung`,
's63e457bc703a3030': `E-Mail`,
's63e71d20d1eaca93': `Deutsch`,
's64a6dcf9cd66376f': `Das Formular konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's64eb05dfa49088da': `Konten verwalten`,
's64ef2a6c2dd1d3d1': `Bearbeiten`,
's65bbdef13ec0b7ff': `Titel eingeben`,
's65bf0994a56d0c51': `Hier können Sie einen kurzen Fließtext eintragen. Für die tabellarische Auflistung z.B. der
					Ausstellungshistorie verwenden Sie den Bereich Lebenslauf.
				`,
's67749057edb2586b': `Abmelden`,
's6801787e32a10951': `In der Werkverzeichnung des Kunstfonds können Sie Ihr Künstlerprofil, Ihren Lebenslauf und
					Ihre Kunstwerke so einfach oder detailliert bearbeiten, wie Sie möchten.
				`,
's68631cfbcbebfc30': `Keine Konten gefunden`,
's69201fa8972b3e66': `Das Kunstwerk enthielt keine Daten - es wurde permanent gelöscht.`,
's6c3970ea23f6d93a': `Profil Berechtigungen`,
's6cbf5b3254dcbbf3': `Code nochmal anfordern`,
's6cedf419e20c815a': `Telefon`,
's6cfdba3baed1f6bc': `Titel des Kunstwerkes`,
's6d8c02aee480af7a': `Menü`,
's6e0f65a28704ec9f': `Name des Abschnitts`,
's70d885806b56289e': `aktiviert`,
's72f2a02e2a5a4ee3': `Bilder / Medien`,
's73cdb5bba903a27f': `Ersetzen`,
's743edce4a1ed0b34': `Ist aktiviert`,
's744bab01318e5b27': `Konto speichern`,
's77585085193f50c2': `Falsches Linkformat`,
's7858dcc121b436fc': `Das Profil konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's7c34d7a176d95aa0': `Jahr (Ende)`,
's7d38fc29b20f951b': `Grundlegende Informationen`,
's7d8d449c7a0483c8': `Keine Bilder / Medien vorhanden`,
's7dfa029dab05b042': `Kunstwerke bearbeiten`,
's7f1c2ac56a84f421': `Willkommen zu der Werkverzeichnung der Stiftung Kunstfonds`,
's804ca9ce39d5c56b': `Code Anfordern`,
's814ca8490eb230c0': `Kunstwerk hinzugefügt`,
's821ef36d63a8a111': `Zuletzt geändert`,
's82665b2ffabc9c0a': `Website`,
's832ef3a7593a8b05': `Bildunterschrift`,
's846b3790b6e25c1c': `Konto`,
's855aaab4663315b4': `Kunstwerke`,
's8641b6c3688a0cec': `Profil bearbeiten`,
's8677baad3bf6b295': `Lebenslauf speichern`,
's87a55464116c3f48': `runter`,
's87bae2710b2492c3': `Kontakt`,
's8a0e1c7e1ec3133b': `Testprofil`,
's8a6c0085cb6c64a0': `Geschlecht`,
's8ac6ca24f22e5e3f': `Verkauft`,
's8d8622e68bd10109': `Bearbeiter*In einladen`,
's927497135cdabe78': `Unbenannt`,
's92921878e886e36d': `Laufzeit`,
's92fef0e2fba7468c': `Spracheinstellungen`,
's931ba7d0f5588be8': `Standard Sprache`,
's93c046d8c5bb2047': `Geburtstag`,
's948766ad031e1c27': `E-Mail`,
's9502410942da6b88': `Lebenslauf Eintrag`,
's95a75a2392af5780': `Nachname`,
's965c503c3e42fdfe': `Aktiviert`,
's98b32ef4a0856c08': `Kein Titel`,
's99f110d27e30b289': `Titel`,
's9b1394a1375404d4': `Ist Nachlass?`,
's9c470e19f779025e': `einmal angemeldet`,
's9cafd50042887a6c': `Material wählen ...`,
's9cbe5dc90f48558d': str`Die Serie "${0}" wird sofort gelöscht. Die Kunstwerke in der Serie bleiben in der Liste der Kunstwerke.`,
's9cf7402fe920f7d1': `Land`,
's9d0a3b8de87941d8': `Kaufpreis`,
's9d8b8aa2b404c2c8': `Einstellungen`,
's9e92503bfc80d2d6': `Gattung/Medium des Kunstwerks`,
'sa080ed80aad67329': `Datei zu lang`,
'sa1dc38bb8e8339f3': `Künstlername`,
'sa2686cd9fcc26088': `Kunstwerk speichern`,
'sa48f81f001b893d2': `NutzerIn`,
'sa512fd0aa10743a6': `Neues Konto`,
'sa5d2f9afa34fd3c3': `Login Code anfragen`,
'sa65a95e0199f2647': `Fehlerhafte Eingabe`,
'sa6f2645578b2d2bc': `Jahr`,
'sa7e28eff7384ceee': `Als wichtig markieren`,
'sa84953c4bf74abca': `Serien bearbeiten`,
'sa87645df04d5260a': `Das Kunstwerk wurde nach Gelöschte Einträge verschoben. Es wird in 7 Tagen permant gelöscht.
				`,
'sabe442739ffbe072': `Maximale Größe`,
'sad3e3c8146fc920f': `Status`,
'sae239213b7c70376': `Zustand`,
'sae42bf06e5c4bbe5': `E-Mail eingeben`,
'saffa23915bede5a1': `PLZ`,
'sb01af87e9ecf6fe9': `Es gibt ungesicherte Änderungen. Wollen Sie das Formular wirklich verlassen?`,
'sb061ff5a347a296e': `Profil`,
'sb0f8ba7eed402d21': `Lebenslauf gespeichert`,
'sb19c9290038389c0': `Material`,
'sb28076159e581755': `Bilder / Medien bearbeiten`,
'sb971debc37bf0b54': `Eigentümer`,
'sb9cdfae56d72cfe2': `Provenienz`,
'sbc2cea55676b3aca': `Geben Sie die E-Mail Adresse ein, an den der Login Code gesendet werden soll.`,
'sbcb056c74785d2c5': `(Unbenannt)`,
'sbe9e66b2467b273b': `Kunstwerk Berechtigungen`,
'sbec9dc4ffcfd8ecb': `Typ wählen ...`,
'sc14632800a845f4a': `Tags auswählen`,
'sc2c901ea4b99a96a': `Nachlass`,
'sc2d94a6963318685': `Künstler*Innen-Profil bearbeiten`,
'sc3693cd85fbd07d3': `Kein Boolean`,
'sc4801f580ac4e73d': `Operator`,
'sc4bbedc293a299bb': `Datei nicht vorhanden`,
'sc592307ea80f16b9': `Unbekannt`,
'sc5a11c2dd9ab8cec': `Privat`,
'sc5b9d5ea3bd15972': `Diese Nummer wird bei der Verzeichnung automatisch aufsteigend vergeben`,
'sc622b6f2e8694aac': `Bilder / Medien speichern`,
'sc6a7cb64b423cffa': `Verliehen`,
'sc7bcd1669fd8e336': `Bestandteil hinzufügen`,
'sc8da3cc71de63832': `Anmelden`,
'sc93a9aa3e5bcbf5d': `Profil speichern`,
'sca08a0a32920416b': `Keine Kunstwerke gefunden.`,
'sca7fed2bef53cb99': `Rolle`,
'sca974356724f8230': `Anmelden`,
'scaf5b41120861d9a': str`Der Login-Code konnte nicht gesendet werden`,
'scb489a1a173ac3f0': `Ja`,
'scb7ed089a649241c': `Neues Konto anlegen`,
'scbbbf3cce4237b03': `Pflichtfeld`,
'scc316262f13a1da8': `Keine Zahl`,
'scd6ac002544eb0d6': `Wert Kategorie`,
'scf8fcc133a2f8f5b': `Profilname eingeben`,
'scfa9e1e719a45ae3': `Maximale Länge Überschritten`,
'scfce1140fb4a263f': `Profil auswählen`,
'sd1f44f1a8bc20e67': `E-Mail`,
'sd2223afb7d6b100d': `Typ`,
'sd24d2099f6f4a88b': `Tabellarischer Lebenslauf`,
'sd33f062c19e2e331': `Name`,
'sd55bb7d228718163': `keine E-Mail Adresse`,
'sd600892f7fabe04c': `Entwurf`,
'sd80496e9c65f1366': `Anmerkungen`,
'sd9aa257179753e02': `Datei hochladen`,
'sda5c7c6d6aeb3699': `Die Einladung wurde erfolgreich verschickt.`,
'sdc04126761bb7455': `runter`,
'sdc673e73b5c13aea': `Löschen`,
'sdcb9b5b53f8c8fc7': `Entfernte Kunstwerke ohne Daten werden direkt, mit Angaben nach einer Frist von sieben Tagen
					gelöscht.
				`,
'sdd1ff479d04ac140': `Öffentlich`,
'se077a13a6f7be5e3': `Zeitraum der Zusammenarbeit`,
'se17f76f493601b62': `https://... (Direkter Link zum Kunstwerk)`,
'se2abc0f78904006c': `Zu viele Einträge`,
'se2c78eaae9032faa': `Objekte und Maße`,
'se330c71fbddb77c9': `Geburtsort`,
'se3e144f19cb4e18f': `einmal angemeldet`,
'se48719d765fc8fde': `Serie Löschen`,
'se48bd9e6c2a3109d': `Konten`,
'se625ae9f34125ec4': `Bitte wählen`,
'se629b41ac62684a1': `zusätzliche Urheberrechte`,
'se68398e3c2c760b2': `Code`,
'se6a9371ded4b10d6': `Bestandteile`,
'se6ae9710a2dbbf88': `Verleihungshistorie`,
'se7238e44f9dcacee': `Jahr`,
'se77e76829df75be9': `Serie hinzufügen`,
'se7bee6e9a9b5394c': `Privat`,
'se80d078fe2e23924': `Kein Array`,
'se82d6481ffb5248f': `Mediendaten gespeichert`,
'se926a1be4644b09d': `Angemeldet bleiben`,
'sea4f08110bb8f15d': `Entfernen`,
'seb4bdd2a26f2ea23': `Bitte auswählen`,
'sebec66b8aa58f583': `Keine Zahl`,
'sec72dce4f74b5328': `Erstellungsdatum (Beginn)`,
'sec7b97c2e906c8fc': `Eingeben...`,
'seca29ce17b31f70d': `Veröffentlichung`,
'see1bbbec880f6085': `Nutzungshinweise`,
'see2fcaedf9743ec6': `Ort`,
'sef16aaaba716aa7d': `Anzahl an Objekten`,
'sef49aec68fd1dc66': `Name`,
'sf0175a45c199e862': str`Code gesendet an ${0}`,
'sf21fb4f4b9e8911f': `Breite`,
'sf3282d80d86fcdc4': `PDF ansehen`,
'sf3fe6b5fdb85d50a': `löschen`,
'sf4b72165f60f5b60': `Öffentlich`,
'sf61497d51f2c663f': `Gelöschte Einträge`,
'sf678df8359ba6257': `Fehler beim Hinzufügen des Kunstwerks`,
'sf6947e1f919e6815': `Minimale Länge Unterschritten`,
'sf74160a9dc86ab8f': `Auflösung`,
'sf76a183603d87542': `Profil für NutzerIn anlegen`,
'sf78636872ec15cbb': `Geben Sie hier den Login Code ein, den Sie per Email erhalten haben.
					Klicken Sie "Angemeldet bleiben" an, wenn Sie auf Ihrem Rechner langfristig angemeldet bleiben wollen.`,
'sf9e39190a7ba37b4': `Profil gespeichert`,
'sfa31cf00458e1d13': `Mehr Filter`,
'sfa766d8b35773ac0': `ggf. Todesort`,
'sfa889eb8ea9148b1': `Datierung`,
'sfbb763a33ac5097b': `Aktueller Zustand`,
'sfcdd8e672c75b713': `Adresse`,
'sfde5ee686b1d3d02': `Urheberrechte`,
'sfe46f400c6b86658': `ansehen`,
'sfe4a9344e132c10a': `Verwendete Materialien`,
'sff9d51b6c5a73163': `Alternativer Text`,
'sffdadf0a576fd802': `Höhe`,
'seae1c1bb7c02a640': `error/invalidCsrfToken`,
's7a851c97b5bc30b8': `error/notIntBool`,
's11366a6d9faff00f': `error/trueRequired`,
'sa0541ec14dd4b89c': `error/falseRequired`,
'sb8bf01b381b893bb': `error/oneRequired`,
'sb41210de4f64f7e1': `error/zeroRequired`,
'sdc8ff1d18f2ecc66': `error/notZeroPosInt`,
's24ed5ee1f343a2b4': `error/uploadPartial`,
's881e3183ff4b701d': `error/uploadMissingTmpDir`,
's860dbc68e0f2b4c8': `error/uploadUnableToWrite`,
'sc21dc776d25eb55a': `error/uploadExtensionFailure`,
'sc34b221a0b8bc676': `error/uploadGenericError`,
'sfb608c3ab924e85c': `error/uploadCategoryNotAllowed`,
's9739d36feb7c7948': `error/uploadTypeNotAllowed`,
's0994965ba38c3639': `content_language_tooltip`,
'saae1c70e168b45b4': `Admin`,
's09242e07b5b90ca0': `Id`,
's8362459ef00898be': `placeholder_artwork_subtitle`,
'sb651be2136f4a42c': `placeholder_artwork_description`,
's37f2ed08cc75dc54': `placeholder_artwork_technique`,
'se80bc227790b7f05': `tooltip_artwork_series`,
'scbf29ce484222325': ``,
'sd27dd35de5df9273': `placeholder_artwork_purchasingPrice`,
's0bd4477ce8ffb997': `placeholder_artwork_valueCategory`,
'se5e21c784a22e2bc': `placeholder_artwork_currentCondition`,
's3713b9bb37a2509a': `placeholder_artwork_usageNotes`,
'sf9e6e6ef197c2b25': `test`,
's2474e7fb1aec9f05': `Test`,
    };
  